import type { WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId } from '../../constants';

import { getMembersAreaUmbrellaPublicAPI } from '../../editor/services/members-editor-script';
import { MembersAreaContext } from '../../types/public-apis';

type ComponentBuilderFn = Parameters<
  WidgetBuilder['configureConnectedComponents']
>[1];

const COLLAPSABLE_ELEMENT_IDS = [
  ElementId.HeaderContainer,
  ElementId.MenuContainer,
];

const configureComponentAsCollapsable: ComponentBuilderFn = (builder) => {
  builder.behavior().set({ preventHide: false });
};

const configureWidgetGFPP = (
  builder: WidgetBuilder,
  membersAreaContext: MembersAreaContext,
) => {
  const isMembersAreaV2Context = membersAreaContext === MembersAreaContext.V2;
  const isMembersAreaV3Context = membersAreaContext === MembersAreaContext.V3;

  builder.gfpp().set('help', {
    id: isMembersAreaV2Context
      ? '1216e503-61fb-479f-a318-9af886dd2769'
      : 'a26581e1-7f77-4094-b043-ee74b8c2c12a',
  });
  builder.configureConnectedComponents(
    'horizontalMenu1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: isMembersAreaV2Context
          ? 'ac4ace90-6edf-40c3-91d2-707d554e9e07'
          : '4c8ba651-aa3f-45c2-82ae-abf3f856b3e6',
      });
    },
  );

  if (isMembersAreaV3Context) {
    builder.configureConnectedComponents(
      'expandableMenu1',
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder.gfpp().set('help', {
          id: '667fd515-1ffb-4879-9fa9-bad9a145aef6',
        });
      },
    );
  }

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
  builder.gfpp().set('widgetPlugins', {
    behavior: 'HIDE',
  });
};

const configurePageWidget = (builder: WidgetBuilder) => {
  builder.behavior().set({ removable: false, duplicatable: false });
};

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  const membersAreaContext = await membersAreaPublicAPI.getMembersAreaContext();

  configurePageWidget(widgetBuilder);
  configureWidgetGFPP(widgetBuilder, membersAreaContext);

  COLLAPSABLE_ELEMENT_IDS.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(
      elementId,
      configureComponentAsCollapsable,
    );
  });
};
